import React from "react"
import apiHeader from "../../../assets/images/products/netstorsys/versand/versand_header.png"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Outlook from "../Outlook"

const Versand = () => {

  return (
    <>
      <section className="services-details-area pt-100" style={{
        marginBottom: "3em"
      }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={apiHeader} alt="API - Application Programming Interfaces" />
              </div>

              <div className="">
                <span className="products-sub-title">Blitzschnell liefern</span>
                <h1 className={"pt-3"}>Versand mit NETSTORSYS und NETVERSYS </h1>
                <p>
                  Der Kunde hat bestellt und scharrt mit den Füßen. Wo bleibt die Versand-E-Mail? Verzögert sich Ware
                  auf der letzten Logistik-Meile, vergeben Händler wertvollen Vertrauensvorschuss. Cold Case
                  Online-Bestellung? Lieber <b>zügig versenden,
                  pünktlich
                  liefern, Kunden überzeugen und durch herausragende Bewertungen neue Kunden gewinnen.</b>
                </p>
                <h2>Auch bei der Lieferqualität überzeugen</h2>
                <p>
                  Unser Warehouse Management System sorgt für schnelle Kommissionierung und eine aufgeräumte
                  Versandzone. Aber die beste Lagerverwaltung nützt nichts, wenn die Ware am Lkw-Dock staut oder zu
                  teuer verschickt wird. NETSTORSYS übergibt den externen Transportauftrag durch seine herausragende
                  Schnittstellenoffenheit reibungslos an jedes Versandsystem. Ist die Ware erst einmal kommissioniert
                  und verpackt, genügen wenige Klicks, um sie auf die Reise zu schicken. Am besten gleich über die
                  Versandsoftware schlechthin.
                </p>

                <h2>Alle Carrier-Register ziehen</h2>
                <p>
                  Mit unserem Multicarrier-Versandsystem <Link to="/versandsoftware">NETVERSYS</Link> bildet <Link
                  to="/lagerverwaltung">NETSTORSYS</Link> ein organisches Duo, das dieselben
                  fortschrittlichen Technologien teilt. Dank regelbasierter Frachtführer-Auswahl kommen Artikel <b>am
                  schnellsten, sichersten und günstigsten ans gewünschte Ziel</b>. Der ganze Papierkram für Gefahrgut
                  oder
                  Zoll wird on the fly abgewickelt. Die umfangreiche Auswahl an Carriern und Services wächst mit jedem
                  Software-Release. Profitieren Sie von allen Stärken der Speditionen und KEP-Dienstleister. Sparen Sie
                  sich die Kostentreiber.
                </p>
                <h2>Geräuschlos versenden</h2>
                <p>
                  NETVERSYS automatisiert den Versand und läuft bei Bedarf im Blackbox-Modus: Ihr Logistikteam muss gar
                  nicht erst die vertraute WMS- oder ERP-Oberfläche verlassen. Hält auch bei zehntausenden Sendungen pro
                  Tag und in der Peak Season den Ruhepuls. Kein Systemwechsel, aber intelligenter Carrier-Wechsel von
                  Fall zu Fall und wirtschaftlich gebündelter Versand, inklusive modernem EDI-Abrechnungsstandard und
                  entkoppelter Auftragspools für Prozessfreiheit zwischen Kommissionierung und Verladerampe.</p>
                <p>
                  Versender, Versanddienstleister und Empfänger sind lückenlos und jederzeit per Unified Track & Trace
                  über den
                  Status der Lieferung informiert. <Link to="/lagerverwaltung/netstorsys/retoure">Retouren</Link> laufen
                  dank nahtloser Kommunikation genauso geschmeidig.
                </p>
                <Outlook text="Mit NETSTORSYS und NETVERSYS abliefern." />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Versand
