import Layout from "../../../components/App/Layout"
import Seo from "../../../components/App/Seo"
import Navbar from "../../../components/App/Navbar"
import Footer from "../../../components/App/Footer"
import React from "react"
import Versand from "../../../components/ProductDetails/netstorsys/Versand"

const VersandPage = () => {
  return (
    <Layout>
      <Seo title={"Moderne Schnittstellen zur grenzenlosen Interaktion."}
           description={"Moderne Webservice Schnittstellen auf Basis von HTTP/Rest für die Interoperabilität " +
             "mit anderen Systemen wie Shopsystemen, ERP oder CRM Systemen."}
           image={"/images/og/netstorsys-main-og.png"}
      />
      <Navbar />
      <Versand />
      <Footer />
    </Layout>
  )
}

export default VersandPage
